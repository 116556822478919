import React from "react"
import { Link } from "gatsby"

const fourOhFour = () => {
  return (
    <div className="flex flex-column items-center justify-start">
      <div className="tc pt4 pt0-ns tl-ns">
        <h2>404 Not found!</h2>
      </div>
      <div>
        <p>
          Hello friend! I don't know what you were looking for, but there's nothing to see here!
        </p>
      </div>
      <div className="episode-container w5 pa2 center tc br-pill mt4">
        <Link to="/">Click here to go back!</Link>
      </div>
    </div>
  )
}

export default fourOhFour
